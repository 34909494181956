import React, { Component } from 'react'
import { Input, Typography } from 'antd'
import classNames from 'classnames'
import styles from './previewInput.scss'
import { api } from '../../api'

const { Text } = Typography

class PreviewInput extends Component {
    state = {
        _id: this.props._id,
        value: this.props.value,
    }

    onChange = ev => {
        this.setState({
            value: ev.target.value,
        })
    }

    onBlur = async () => {
        const { value } = this.state
        const { url, id } = this.props

        await api.put(url, {
            id,
            name: value,
        })
    }

    getDynamicInput = () => {
        const { value } = this.state
        const { placeHolder } = this.props
        return (
            <Input
                placeholder={placeHolder}
                value={value}
                onChange={this.onChange}
                className={classNames(styles.inactiveInput, styles.input)}
                onBlur={this.onBlur}
            />
        )
    }

    render() {
        const { isEditable, value } = this.props
        return isEditable ? (
            this.getDynamicInput()
        ) : (
            <Text className={styles.input}>{value}</Text>
        )
    }
}

export default PreviewInput
