import React from 'react'
import { Result, Button } from 'antd';
import {withRouter, Link} from 'react-router-dom'
import MainLayout from '../../layout/mainLayout'


const Updated = props => {

    const title = JSON.parse(decodeURIComponent(props.match.params.title)).title
    const message = `Your article ${title} has just been updated!`

    const redirect = () => {
        window.location = 'https://calendar.snkrhds.com'
    }

    return  <MainLayout>
        <Result
            status="success"
            title="Article Updated"
            subTitle={message}
            extra={[
                <Button type="primary" key={2} onClick={redirect}>
                    Watch it online
                </Button>
                ,
                <Link to='/dashboard' key={3}>
                    <Button>
                        Go back to dashboard
                    </Button>
                </Link>
            ]}
        />
    </MainLayout>
}

export default withRouter(Updated)