import React, { Component } from 'react'
import StoreUploader from '../uploaders/storeUploader'
import { api } from '../../api'
import Validator from '../../util/validator'
import { Row } from 'antd'
import StoreList from '../lists/storeList'

class StoreUploaderContainer extends Component {
    state = {
        items: [],
    }

    componentDidMount = () => {
        this.fetchItems()
    }

    fetchItems = async () => {
        const { url } = this.props
        const items = await api.get(url)

        this.setState({
            items: items.data,
        })
    }

    addItem = async item => {
        const { url } = this.props
        const isValid = Validator.isStoreValid(item)

        if (isValid) {
            const brand = await api.post(url, {
                locality: item.locality,
                image: item.image,
            })

            const { items } = this.state
            items.push(brand.data)
            this.setState(items)

            // return true
            window.location.reload()
        }
    }

    remove = async id => {
        console.info('#removed')
        console.info(id)
        const { items } = this.state
        await api.delete(this.props.url, {
            data: { id: id },
        })
        const removeIndex = items
            .map(function(item) {
                return item._id
            })
            .indexOf(id)
        items.splice(removeIndex, 1)
        this.setState(items)
        window.location.reload()
    }

    render() {
        const { items } = this.state
        const { uploaderUrl, url } = this.props

        return (
            <Row justify="start" align="top" type="flex">
                <StoreUploader
                    add={this.addItem}
                    uploaderUrl={uploaderUrl}
                    url={url}
                />
                {items && items.length !== 0 && (
                    <StoreList remove={this.remove} />
                )}
            </Row>
        )
    }
}

export default StoreUploaderContainer
