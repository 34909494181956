import React from 'react'
import styles from './preview.scss'
import Validator from '../../util/validator'
import PreviewEditorLayer from './previewEditorLayer'
import PreviewSelect from './previewSelect'
import { storeConfig } from '../../config/stores'

class StorePreview extends React.Component {
    state = {
        image: this.props.image,
        value: this.props.value,
        loading: false,
    }

    render() {
        const { image } = this.state
        const { remove, url, uploaderUrl, _id, locality } = this.props

        return (
            <div className={styles.container}>
                <PreviewEditorLayer
                    id={_id}
                    name="image"
                    imageRoute={image}
                    url={url}
                    uploaderUrl={uploaderUrl}
                    beforeUpload={Validator.isImageValid}
                    onChange={this.handleChange}
                    remove={remove}
                />
                <PreviewSelect
                    _id={_id}
                    options={storeConfig.locality}
                    url={url}
                    locality={locality}
                />
            </div>
        )
    }
}

export default StorePreview
