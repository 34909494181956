const axios = require('axios')
import config from '../config'
import { notification } from 'antd'
import store from '../src/redux/store'

export const showNotification = (message, type) => {
    notification[type]({
        message: message,
        duration: 2,
    })
}

export const routes = {
    brandUpload: '/upload/brand',
    storeUpload: '/upload/store',
    categoryUpload: '/upload/category',
    productImageUpload: '/upload/product-image',
    categoryApi: '/category',
    brandApi: '/brand',
    storeApi: '/store',
    login: 'auth/login',
    logout: 'auth/logout',
    signUp: '/auth/signup',
    publish: '/product/publish',
    update: '/product/',
}

export const api = {
    url: config.api,
    static: config.static,

    get: async (route, data) => {
        return await axios
            .get(config.api + route, {
                params: data,
                headers: {
                    Authorization: 'Token ' + store.getState().token
                }
            })
            .then(response => {
                if (response.data.message) {
                    showNotification(response.data.message, 'success')
                }
                return {
                    success: true,
                    data: response.data,
                }
            })
            .catch(error => {
                showNotification('Operation failed', 'error')
                return {
                    success: false,
                    error,
                }
            })
    },

    post: async (route, data) => {
        return await axios
            .post(config.api + route, data)
            .then(response => {
                if (response.data.message) {
                    showNotification(response.data.message, 'success')
                }
                return {
                    success: true,
                    data: response.data,
                }
            })
            .catch(error => {
                showNotification('Operation failed', 'error')
                return {
                    success: false,
                    error,
                }
            })
    },

    put: async (route, data) => {
        return await axios
            .put(config.api + route, data)
            .then(response => {
                if (response.data.message) {
                    showNotification(response.data.message, 'success')
                }
                return {
                    success: true,
                    data: response.data,
                }
            })
            .catch(error => {
                showNotification('Operation failed', 'error')
                console.info(error)
                return {
                    success: false,
                    error,
                }
            })
    },

    update: async (route, data) => {
        return await axios
            .put(config.api + route, data)
            .then(response => {
                if (response.data.message) {
                    showNotification(response.data.message, 'success')
                }
                return {
                    success: true,
                    data: response.data,
                }
            })
            .catch(error => {
                showNotification('Operation failed', 'error')
                console.info(error)
                return {
                    success: false,
                    error,
                }
            })
    },

    delete: async (route, data) => {
        return await axios
            .delete(config.api + route, data)
            .then(response => {
                if (response.data.message) {
                    showNotification(response.data.message, 'success')
                }
                return {
                    success: true,
                    data: response.data,
                }
            })
            .catch(error => {
                showNotification('Operation failed', 'error')
                return {
                    success: false,
                    error,
                }
            })
    },
}
