import store from '../redux/store'

class StorageApi {

    getToken = () => {
        return store.getState().token
    }

}

export default new StorageApi()