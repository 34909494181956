import React, { Component } from 'react'
import { Menu, Dropdown, Icon } from 'antd'
import styles from './styles.scss'

const menu = (
    <Menu>
        <Menu.Item key="0">Profile</Menu.Item>
        <Menu.Divider />
        <Menu.Item key="1">Logout</Menu.Item>
    </Menu>
)

class ProfileMenu extends Component {
    render() {
        return (
            <Dropdown
                overlay={menu}
                trigger={['click']}
                className={styles.dropdown}
            >
                <span className="ant-dropdown-link">
                    Vu Nguyen <Icon type="down" />
                </span>
            </Dropdown>
        )
    }
}

export default ProfileMenu
