import React, { Component } from 'react'
import MainLayout from '../../layout/mainLayout'
import { Typography } from 'antd'
import BasicUploaderContainer from '../../uploaderContainers/basicUploaderContainer'
import { routes } from '../../../api'

const { Title } = Typography

class Brands extends Component {
    render() {
        return (
            <div>
                <MainLayout>
                    <Title>Brands</Title>
                    <BasicUploaderContainer
                        url={routes.brandApi}
                        uploaderUrl={routes.brandUpload}
                    />
                </MainLayout>
            </div>
        )
    }
}

export default Brands
