import React, { Component } from 'react'
import { api, routes } from '../../api'
import StorePreview from '../previews/storePreview'

class StoreList extends Component {
    state = {
        isLoading: false,
        stores: [],
    }

    componentDidMount() {
        this.fetchStores()
    }

    fetchStores = async () => {
        const stores = await api.get('/store')
        this.setState({
            stores: stores.data,
            isLoading: false,
        })
    }

    render() {
        const { stores } = this.state
        const { remove } = this.props

        return stores.map(item => {
            return (
                <StorePreview
                    image={item.image}
                    url={routes.storeApi}
                    uploaderUrl={routes.storeUpload}
                    remove={remove}
                    locality={item.locality}
                    _id={item._id}
                    key={item._id}
                />
            )
        })
    }
}

export default StoreList
