import React, { Component } from 'react'
import { Tag } from 'antd'
import styles from './styles.scss'
import classNames from 'classnames'

const { CheckableTag } = Tag

export const ProductStatuses = {
    ONLINE: {
        title: 'Online',
        data: 'online',
        color: '#40b395',
    },
    COMING_SOON: {
        title: 'Coming Soon',
        data: 'coming-soon',
        color: '#222',
    },
    SOLD_OUT: {
        title: 'Sold Out',
        data: 'sold-out',
        color: '#df3c53',
    },
}

class StatusSelector extends Component {
    state = {
        statuses: ProductStatuses,
        selectedStatus: this.props.status
            ? ProductStatuses[this.props.status.toUpperCase().replace(' ', '_')]
            : {},
    }

    handleChange = tagKey => {
        const { onChange } = this.props
        const { statuses } = this.state
        this.setState({
            selectedStatus: statuses[tagKey],
        })
        onChange(statuses[tagKey].data)
    }

    render() {
        const { statuses, selectedStatus } = this.state
        const { hideText } = this.props

        return (
            <div
                style={{
                    textAlign: hideText ? 'center' : '',
                }}
            >
                {Object.keys(statuses).map(tagKey => {
                    let tag = statuses[tagKey]
                    return (
                        <CheckableTag
                            key={tag.title}
                            checked={tag.isChecked}
                            className={classNames(
                                styles.tag,
                                hideText ? styles.dotsOnly : ''
                            )}
                            style={{
                                backgroundColor: tag.color,
                                opacity:
                                    tag.data === selectedStatus.data ? 1 : 0.3,
                            }}
                            onChange={checked =>
                                this.handleChange(tagKey, checked)
                            }
                        >
                            {!hideText && tag.title}
                        </CheckableTag>
                    )
                })}
            </div>
        )
    }
}

export default StatusSelector
