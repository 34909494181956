import React from 'react'
import styles from './preview.scss'
import Validator from '../../util/validator'
import PreviewInput from './previewInput'
import PreviewEditorLayer from './previewEditorLayer'

class Preview extends React.Component {
    state = {
        image: this.props.logo,
        value: this.props.value,
        loading: false,
    }

    getEditorLayer = () => {
        const { image } = this.state
        const { remove, url, uploaderUrl, _id, isEditable, name } = this.props

        return (
            <div className={styles.container}>
                <PreviewEditorLayer
                    id={_id}
                    name="image"
                    imageRoute={image}
                    url={url}
                    uploaderUrl={uploaderUrl}
                    beforeUpload={Validator.isImageValid}
                    onChange={this.handleChange}
                    remove={remove}
                />
                <PreviewInput
                    id={_id}
                    placeholder="Add brand title"
                    value={name}
                    url={url}
                    isEditable={isEditable}
                />
            </div>
        )
    }

    getStaticLayer = () => {
        const { image } = this.state
        const { name } = this.props

        return (
            <React.Fragment>
                <div className={styles.static} onClick={this.onClick}>
                    <img src={image} alt={name} />
                    <br/>
                </div>
                <span style={{textAlign: 'center', display: 'block', fontSize: '14px', fontWeight: 'bold'}}>{name}</span>
            </React.Fragment>
        )
    }

    onClick = () => {
        const { onClick, _id } = this.props
        const { image } = this.state

        if (onClick) {
            onClick({
                _id,
                image,
            })
        }
    }

    render() {
        const { isEditable } = this.props
        return isEditable ? this.getEditorLayer() : this.getStaticLayer()
    }
}

export default Preview
