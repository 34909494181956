import { Upload, Icon, Button, Select } from 'antd'
import React from 'react'
import styles from './basicUploader.scss'
import { api } from '../../api'
import Validator from '../../util/validator'
import classNames from 'classnames'
import { storeConfig } from '../../config/stores'

const { Option } = Select

class StoreUploader extends React.Component {
    initialState = {
        loading: false,
        image: '',
        locality: storeConfig.locality.local.type,
    }

    constructor() {
        super()
        this.state = this.initialState
    }

    handleChange = async info => {
        const { file } = info
        if (file.status === 'uploading') {
            this.setState({ loading: true })
            return
        }
        if (file.status === 'done') {
            await Validator.readImage(file.originFileObj, image => {

                this.setState({
                    image,
                    loading: false,
                    path: file.response.path,
                })
            })
        }
    }

    onSelectChange = value => {
        this.setState({
            locality: value,
        })
    }

    add = async () => {
        const { path, locality } = this.state
        const { add } = this.props

        const item = await add({
            locality,
            image: path,
        })
        if (item) this.reset()
    }

    reset = () => {
        this.setState(this.initialState)
    }

    getSelect = () => {
        const { locality } = this.state
        return (
            <Select
                className={styles.selectOption}
                defaultValue={locality}
                onChange={this.onSelectChange}
            >
                <Option value={storeConfig.locality.local.type}>
                    {storeConfig.locality.local.title}
                </Option>
                <Option value={storeConfig.locality.global.type}>
                    {storeConfig.locality.global.title}
                </Option>
            </Select>
        )
    }

    render() {
        const { uploaderUrl } = this.props
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        )
        const { image } = this.state

        return (
            <div className={classNames(styles.container, 'basic-uploader')}>
                {!image && (
                    <Upload
                        name="image"
                        listType="picture-card"
                        className={styles.uploader}
                        showUploadList={false}
                        action={`${api.url}${uploaderUrl}`}
                        beforeUpload={Validator.isImageValid}
                        onChange={this.handleChange}
                    >
                        {uploadButton}
                    </Upload>
                )}
                {image && (
                    <img src={image} className={styles.image} alt="avatar" />
                )}
                {this.getSelect()}
                <Button onClick={this.add}>Add Store</Button>
            </div>
        )
    }
}

export default StoreUploader
