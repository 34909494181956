import React, { Component } from 'react'
import { Menu, Icon, Layout } from 'antd'
import logo from '../logo/logo.png'
import styles from './sidebar.scss'
import { Link } from 'react-router-dom'
import { menuItems } from './menuItems'

class Sidebar extends Component {
    render() {
        return (
            <Layout.Sider theme="light">
                <div className="home">
                    <img src={logo} className={styles.logo} />
                </div>
                <Menu>
                    {menuItems.map((item, index) => (
                        <Menu.Item key={index} className={styles.menuItem}>
                            <Icon type={item.icon} />
                            <Link to={item.target} className={styles.link}>
                                {item.title}
                            </Link>
                        </Menu.Item>
                    ))}
                </Menu>
            </Layout.Sider>
        )
    }
}

export default Sidebar
