import React from 'react';
import StorageApi from './api/StorageApi'
import {Route, Redirect} from 'react-router-dom'

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={props => (
            StorageApi.getToken() ? (
                    <Component {...props}/>
                ) :
                (
                    <Redirect
                        to={{
                            pathname : '/login',
                            state: {from: props.location}
                        }}
                    />
                )
        )}
    />
)

export default PrivateRoute