import React, { Component } from 'react'
import BasicUploader from '../uploaders/basicUploader'
import Preview from '../previews/preview'
import { api } from '../../api'
import Validator from '../../util/validator'
import { Row } from 'antd'

class BasicUploaderContainer extends Component {
    state = {
        items: [],
    }

    componentDidMount = () => {
        this.fetchItems()
    }

    fetchItems = async () => {
        const items = await api.get(this.props.url)
        this.setState({
            items: items.data,
        })
    }

    addItem = async item => {
        const { url } = this.props
        const isValid = Validator.isBrandValid(item)
        if (isValid) {
            const brand = await api.post(url, {
                name: item.name,
                logo: item.logo,
            })

            const { items } = this.state
            items.push(brand.data)
            this.setState(items)

            return true
        } else {
            return false
        }
    }

    remove = async id => {
        const { items } = this.state
        await api.delete(this.props.url, {
            data: { id: id },
        })
        const removeIndex = items
            .map(function(item) {
                return item._id
            })
            .indexOf(id)
        items.splice(removeIndex, 1)
        this.setState(items)
    }

    renderItems = () => {
        const { items } = this.state
        const { url, uploaderUrl } = this.props
        return items.map(item => {
            return (
                <Preview
                    logo={item.logo}
                    url={url}
                    uploaderUrl={uploaderUrl}
                    remove={this.remove}
                    name={item.name}
                    _id={item._id}
                    key={item._id}
                    isEditable={true}
                />
            )
        })
    }

    render() {
        const { items } = this.state
        const { uploaderUrl } = this.props

        return (
            <Row justify="start" align="top" type="flex">
                <BasicUploader add={this.addItem} url={uploaderUrl} />
                {items && items.length !== 0 && this.renderItems()}
            </Row>
        )
    }
}

export default BasicUploaderContainer
