import React, { Component } from 'react'
import { Avatar, Modal, Spin } from 'antd'
import { api, routes } from '../../../api'
import Preview from '../../previews/preview'
import styles from './styles.scss'

class BrandSelector extends Component {
    state = {
        isVisible: false,
        selectedBrand: {},
        brands: [],
        isLoading: true,
    }

    click = () => {
        this.fetchBrands()
        this.setState({
            isVisible: true,
        })
    }

    handleCancel = () => {
        this.setState({
            isVisible: false,
        })
    }

    handleOk = () => {
        this.setState({
            isVisible: false,
        })
    }

    fetchBrands = async () => {
        const brands = await api.get('/brand')
        this.setState({
            brands: brands.data,
            isLoading: false,
        })
    }

    onBrandSelected = brand => {
        const { onBrandSelected } = this.props
        onBrandSelected(brand)
        this.setState({
            isVisible: false,
        })
    }

    renderBrands = () => {
        const { brands } = this.state
        if (!brands) return
        return brands.map(brand => {
            return (
                <Preview
                    logo={brand.logo}
                    url={routes.brandApi}
                    uploaderUrl={routes.brandUpload}
                    name={brand.name}
                    _id={brand._id}
                    key={brand._id}
                    onClick={this.onBrandSelected}
                />
            )
        })
    }

    render() {
        const { isVisible, isLoading } = this.state
        const { brand } = this.props

        return (
            <div>
                <Avatar
                    shape="square"
                    className={styles.clickable}
                    src={brand ? brand.image : ''}
                    size={64}
                    icon="user"
                    onClick={this.click}
                />
                <Modal
                    title="Choose Brand"
                    visible={isVisible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    style={{ textAlign: 'center' }}
                >
                    {isLoading ? <Spin size="large" /> : null}
                    {this.renderBrands()}
                </Modal>
            </div>
        )
    }
}

export default BrandSelector
