import React, { Component } from 'react'
import UserApi from '../../../api/UserApi'

class Signup extends Component {

    componentDidMount() {
        this.register()
    }

    register = async() => {
        const result = await UserApi.register();
        console.info(result);
    }

    render() {
        return null
    }
}

export default Signup
