import React from 'react'
import ReactDOM from 'react-dom'
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom'
import Dashboard from './src/components/pages/dashboard/dashboard'
import Brands from './src/components/pages/brands/brands'
import Categories from './src/components/pages/categories/categories'
import Stores from './src/components/pages/stores/stores'
import Products from './src/components/pages/products/products'
import Login from './src/components/pages/login/login'
import Signup from './src/components/pages/signup/signup'
import './src/global.scss'
import ProductList from './src/components/pages/productList/productList'
import Published from './src/components/pages/confirmation/published'
import Updated from './src/components/pages/confirmation/updated'
import Imprint from './src/components/pages/imprint/imprint'
import { Provider } from 'react-redux'
import PrivateRoute from './src/privateRoute'

import store from './src/redux/store'

class App extends React.Component {

    constructor(props) {
        super(props)
        this.state = {value: ''}
    }

    render() {
        return (
            <Provider store={store}>
                <Router>
                    <Switch>
                        <Route path="/login" component={Login} />
                        <PrivateRoute path="/dashboard" component={Dashboard} exact />
                        <PrivateRoute path="/brands" component={Brands} />
                        <PrivateRoute path="/categories" component={Categories} />
                        <PrivateRoute path="/stores" component={Stores} />
                        <PrivateRoute path="/add-product" component={Products} />
                        <PrivateRoute path="/products" component={ProductList} />
                        <PrivateRoute path="/product/:id" component={Products}/>
                        <Route path="/register" component={Signup} />
                        <PrivateRoute path="/published/:title" component={Published}/>
                        <PrivateRoute path="/updated/:title" component={Updated}/>
                        <PrivateRoute path='/imprint' component={Imprint}/>
                        <Route component={Login} />
                    </Switch>
                </Router>
            </Provider>
        )
    }
}

var mountNode = document.getElementById('app')
ReactDOM.render(<App />, mountNode)
