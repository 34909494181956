import React, { Component } from 'react'
import MainLayout from '../../layout/mainLayout'
import TextEditor from '../../editor/textEditor/textEditor'
import { Button, Typography } from 'antd'
import styles from './styles.scss'
import ImprintApi from '../../../api/ImprintApi'

const { Title } = Typography;

class Imprint extends Component {

    state = {
        content: ''
    }

    componentDidMount() {
        this.fetch()
    }

    fetch = async() => {
        const result = await ImprintApi.get()
        this.setState({
            content: result.data
        })
    }

    save = async(content) => {
        console.info(content)
    }

    onChange = (content) => {
        console.info(content)
    }

    render() {
        return (
            <MainLayout title='Imprint'>
                <Title>Imprint Editor</Title>
                <TextEditor
                    onChange={this.onChange}
                    value={this.state.content}
                />
                <Button className={styles.button} type='primary' onClick={this.save}>Save</Button>
            </MainLayout>
        )
    }
}

export default Imprint