import React, { Component } from 'react'
import { Form, Icon, Input, Button } from 'antd'
import UserApi from '../../api/UserApi'

class LoginFormComponent extends Component {

    handleSubmit = (ev) => {
        ev.preventDefault();

        this.props.form.validateFields(async(err, values) => {
            if (!err) {
                const result = await UserApi.login(values);
                if (result.success) {
                    const {onLogin} = this.props
                    onLogin(result.data)
                }
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Item>
                    {getFieldDecorator('username', {
                        rules: [
                            {
                                required: true,
                                message: 'Please input your username!',
                            },
                        ],
                    })(
                        <Input
                            prefix={
                                <Icon
                                    type="user"
                                    style={{ color: 'rgba(0,0,0,.25)' }}
                                />
                            }
                            placeholder="Username"
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('password', {
                        rules: [
                            {
                                required: true,
                                message: 'Please input your Password!',
                            },
                        ],
                    })(
                        <Input
                            prefix={
                                <Icon
                                    type="lock"
                                    style={{ color: 'rgba(0,0,0,.25)' }}
                                />
                            }
                            type="password"
                            placeholder="Password"
                        />
                    )}
                </Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                >
                    Log in
                </Button>
            </Form>
        )
    }
}

const LoginForm = Form.create({ name: 'normal_login' })(LoginFormComponent)

export default LoginForm
