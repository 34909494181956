import React, { Component } from 'react'
import { api } from '../../../api'
import MainLayout from '../../layout/mainLayout'
import { List, Icon } from 'antd'
import styles from './styles.scss'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Popconfirm } from 'antd'

class ProductList extends Component {
    state = {
        isLoading: true,
        products: [],
    }

    componentDidMount() {
        this.fetchProducts()
    }

    fetchProducts = async () => {
        const result = await api.get('/product/list')
        if (result.success) {
            this.setState({
                isLoading: false,
                products: result.data,
            })
        }
        else {
            this.setState({
                isLoading: false,
                products: [],
            })
        }
    }

    delete = async id => {
        const result = await api.delete('/product', {
            data: {
                _id: id,
            },
        })
        if (result.success) {
            window.location.reload()
        }
    }

    getActionButtons = id => {
        return [
            <Popconfirm
                title="Are you sure delete this post?"
                onConfirm={() => {
                    this.delete(id)
                }}
                okText="Yes"
                cancelText="No"
            >
                <span className={styles.icon}>
                    <Icon type="delete" style={{ marginRight: 8 }} />
                    delete
                </span>
            </Popconfirm>,
            <Link to={`/product/${id}`}>
                <span className={styles.icon} onClick={this.onEdit}>
                    <Icon type="edit" style={{ marginRight: 8 }} />
                    edit
                </span>
            </Link>,
        ]
    }

    render() {
        const { products, isLoading } = this.state

        return (
            <MainLayout>
                <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={products}
                    loading={isLoading}
                    renderItem={item => (
                        <List.Item
                            key={item._id}
                            className={styles.listItem}
                            actions={this.getActionButtons(item._id)}
                            extra={
                                <img
                                    width={100}
                                    alt="cover image"
                                    src={item.images[0] && item.images[0].url}
                                />
                            }
                        >
                            <List.Item.Meta
                                title={<a href={item.href}>{item.name}</a>}
                            />
                            <p>Posted: {moment(item.created).fromNow()}</p>
                            <p>Status: {item.status}</p>
                        </List.Item>
                    )}
                />
            </MainLayout>
        )
    }
}

export default ProductList
