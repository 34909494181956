import React, { Component } from 'react'
import BrandSelector from './brandSelector/brandSelector'
import { Input, Row, Col, Steps, Divider, InputNumber } from 'antd'
import CoverUploader from '../coverUploader/coverUploader'
import ReleaseDateSelector from './releaseDateSelector/releaseDateSelector'
import StatusSelector, {
    ProductStatuses,
} from './statusSelector/statusSelector'
import StoreSelector from './storeSelector/storeSelector'
import TextEditor from './textEditor/textEditor'
import { storeConfig } from '../../config/stores'
import ProductImageUploader from '../productImageUploader/productImageUploader'
import PublishButton from './publishButton/publishButton'
import { showNotification } from '../../api'
import CategorySelector from '../categorySelector/categorySelector'
import ProductApi from '../../api/ProductApi'
import {Redirect} from 'react-router-dom'

const { Step } = Steps
let germanStores = []
let globalStores = []

const missing = {
    couponCode: '',
    couponExpiration: '',
    couponDiscount: '',
}

class ProductEditor extends Component {
    initialState = {
        name: '',
        url: '',
        colorway: '',
        styleId: '',
        price: '',
        logo: '',
        brand: '',
        images: [],
        status: '',
        statusCls: '',
        releaseDate: '',
        stores: [],
        description: '',
        publishDate: '',
        hasLocalStore: false,
        hasGlobalStore: false,
        category: '',
        thumbnail: '',
    }

    state = {
        id: this.props.id,
        name: '',
        url: '',
        colorway: '',
        styleId: '',
        price: '',
        logo: '',
        brand: '',
        images: [],
        status: '',
        statusCls: '',
        releaseDate: '',
        stores: [],
        description: '',
        publishDate: '',
        hasLocalStore: false,
        hasGlobalStore: false,
        category: '',
        thumbnail: '',
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.id && this.props.id !== prevProps.id) {
            this.setState(this.initialState)
        }
    }

    componentDidMount() {
        const { id } = this.state
        if (id) {
            this.getProduct(id)
        }
    }

    getProduct = async id => {
        const result = await ProductApi.getProductById(id)
        if (result.success) {
            this.setState({ ...result.data.shoe })
        }
    }

    onBrandSelected = brand => {
        brand.logo = brand.image
        this.setState({
            brand: brand,
            logo: brand.image,
        })
    }

    onStatusChange = status => {
        this.setState({
            status,
            statusCls: status,
            isComingSoon: status === ProductStatuses.COMING_SOON.data ? 1 : 0,
        })
    }

    onDescriptionChange = description => {
        this.setState({ description })
    }

    onTitleChange = ev => {
        const value = ev.target.value
        this.setState({
            name: value,
            url: encodeURI(value.split(' ').join('-')).toLowerCase(),
        })
    }

    onColorWayChange = ev => {
        this.setState({ colorway: ev.target.value })
    }

    onReleaseDateChanged = (momentObject, releaseDate) => {
        this.setState({ releaseDate })
    }

    onStyleIdChange = ev => {
        this.setState({
            styleId: ev.target.value,
        })
    }

    onCoverImageChange = coverImageObject => {
        const { images } = this.state
        let imagesClone = [...images]

        imagesClone[0] = {
            sourceUrl: coverImageObject.sourceUrl,
            seoText: coverImageObject.seoText,
            url: coverImageObject.sourceUrl,
        }

        this.setState({
            images: imagesClone,
            thumbnail: coverImageObject.thumbnail,
        })
    }

    onGermanStoreChanged = stores => {
        germanStores = stores
        this.setState({
            stores: stores.concat(globalStores),
            hasLocalStore: stores.length > 0,
        })
    }

    onCategorySelected = category => {
        this.setState({
            category: category,
        })
    }

    onGlobalStoreChanged = stores => {
        globalStores = stores
        this.setState({
            stores: stores.concat(germanStores),
            hasGlobalStore: stores.length > 0,
        })
    }

    onPriceChange = price => {
        this.setState({ price: price })
    }

    getSteps = () => {
        return Object.keys(this.state).map((step, index) => {
            const stateStep = this.state[step]
            let status

            if (Array.isArray(stateStep)) {
                status = stateStep[0] ? 'finish' : 'wait'
            } else {
                status = stateStep ? 'finish' : 'wait'
            }
            return <Step key={index} title={step} status={status} />
        })
    }

    onProductImageUploaded = fileList => {
        const imagesClone = [this.state.images[0]]
        const reducedFileList = []

        fileList.map(file => {
            reducedFileList.push({
                sourceUrl: process.env.STATIC + '/images/' + file.name,
                seoText: process.env.STATIC + '/images/' + file.name,
                url: process.env.STATIC + '/images/' + file.name,
            })
        })

        this.setState({
            images: imagesClone.concat(reducedFileList),
        })
    }

    publish = async publishDate => {
        const socialMediaLinks = { ...this.state.socialMediaLinks }
        const article = Object.assign(this.state, socialMediaLinks)
        article.orderDate = publishDate
        article.publishDate = publishDate

        const result = await ProductApi.publish(article)
        if (result.success) {
            this.setState({isPublished: true})
        }
    }

    redirectToPublished = () => {
        const {name} = this.state
        const redirectUrl = '/published/' + JSON.stringify({title: name})
        return <Redirect to={redirectUrl}/>
    }

    redirectToUpdated = () => {
        const {name} = this.state
        const redirectUrl = '/updated/' + JSON.stringify({title: name})
        return <Redirect to={redirectUrl}/>
    }

    update = async publishDate => {
        const socialMediaLinks = { ...this.state.socialMediaLinks }
        const article = Object.assign(this.state, socialMediaLinks)
        article.orderDate = publishDate
        article.publishDate = publishDate

        const result = await ProductApi.update(article)
        if (result.success) {
            this.setState({isUpdated: true})
            showNotification('Post Updated!', 'success')
        }
    }

    render() {
        const { brand, name, images, id, status, releaseDate, isPublished, isUpdated } = this.state
        const galleryImages = [...images].splice(1, images.length - 1)
        if (id && !name) return null

        return (
            <Row>
                <Col lg={6} xs={24}>
                    <Steps onChange={this.onChange} direction="vertical">
                        {this.getSteps()}
                    </Steps>
                </Col>
                <Col lg={18} xs={24}>
                    <Row type="flex" justify="start" align="top">
                        <Col span={2}>
                            <BrandSelector
                                onBrandSelected={this.onBrandSelected}
                                brand={brand}
                            />
                        </Col>
                        <Col span={17}>
                            <Input
                                addonBefore="Title"
                                value={name}
                                onChange={this.onTitleChange}
                            />
                        </Col>
                        <Col span={3} offset={2}>
                            <InputNumber
                                placeholder="Price"
                                onChange={this.onPriceChange}
                                value={this.state.price}
                                formatter={value =>
                                    `€ ${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                    )
                                }
                            />
                        </Col>
                    </Row>
                    <Row type="flex" justify="start" align="middle">
                        <Col span={17} offset={2}>
                            <Input
                                addonBefore="Colorway"
                                onChange={this.onColorWayChange}
                                value={this.state.colorway}
                            />
                        </Col>
                        <Divider
                            style={{
                                margin: '4px 0',
                                border: 0,
                                background: 'none',
                            }}
                        />
                        <Col span={17} offset={2}>
                            <Input
                                addonBefore="Style ID"
                                onChange={this.onStyleIdChange}
                                value={this.state.styleId}
                            />
                        </Col>
                    </Row>
                    <Row type="flex" justify="start" align="middle">
                        <Col span={24}>
                            <Divider>Cover Image</Divider>
                            <CoverUploader
                                onChange={this.onCoverImageChange}
                                onUrlChange={this.onCoverImageChange}
                                title="Choose cover image"
                                image={images[0]}
                            />
                        </Col>
                    </Row>
                    <Row type="flex" justify="start" align="middle">
                        <Col span={24}>
                            <Divider>Release Date & Status</Divider>
                            <Col span={9}>
                                <ReleaseDateSelector
                                    onChange={this.onReleaseDateChanged}
                                    releaseDate={releaseDate}
                                />
                            </Col>
                            <Col span={9} style={{ float: 'right' }}>
                                <StatusSelector
                                    onChange={this.onStatusChange}
                                    status={status === 'coming-soon' ? 'COMING_SOON' : status}
                                />
                            </Col>
                        </Col>
                    </Row>

                    <Row type="flex" justify="start" align="middle">
                        <Col span={24}>
                            <Divider>Category</Divider>
                            <CategorySelector
                                onSelect={this.onCategorySelected}
                                selected={this.state.category}
                            />
                        </Col>
                    </Row>

                    <Row type="flex" justify="start" align="middle" gutter={20}>
                        <Col span={24}>
                            <Divider>
                                {storeConfig.locality.local.title}
                            </Divider>
                            <StoreSelector
                                filter={storeConfig.locality.local.type}
                                onChange={this.onGermanStoreChanged}
                                selectedStores={this.state.stores}
                            />
                        </Col>
                        <Col span={24}>
                            <Divider>
                                {storeConfig.locality.global.title}
                            </Divider>
                            <StoreSelector
                                filter={storeConfig.locality.global.type}
                                onChange={this.onGlobalStoreChanged}
                                selectedStores={this.state.stores}
                            />
                        </Col>
                        <Col span={24}>
                            <Divider>Description</Divider>
                            <TextEditor
                                onChange={this.onDescriptionChange}
                                value={this.state.description}
                            />
                        </Col>
                        <Col span={24}>
                            <ProductImageUploader
                                onImageUploaded={this.onProductImageUploaded}
                                images={
                                    this.state.images.length !== 0
                                        ? galleryImages
                                        : []
                                }
                            />
                        </Col>
                    </Row>
                    <Divider />
                    {id ? (
                        <PublishButton publish={this.update} title="Update" />
                    ) : (
                        <PublishButton publish={this.publish} title="Publish" />
                    )}
                </Col>
                {isPublished && this.redirectToPublished()}
                {isUpdated && this.redirectToUpdated()}
            </Row>
        )
    }
}

export default ProductEditor
