import React from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'

const dateFormat = 'YYYY/MM/DD'

const ReleaseDateSelector = props => (
    <DatePicker
        placeholder="Release Date"
        onChange={props.onChange}
        format={dateFormat}
        value={props.releaseDate ? moment(props.releaseDate) : null}
    />
)

export default ReleaseDateSelector
