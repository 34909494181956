import React, { Component } from 'react'
import styles from './previewSelect.scss'
import { Select } from 'antd'
import { api } from '../../api'
import { storeConfig } from '../../config/stores'

const { Option } = Select

class PreviewSelect extends Component {
    state = {
        locality: this.props.locality,
    }

    onChange = value => {
        const { _id, url } = this.props
        this.setState({
            locality: value,
        })
        api.put(url, {
            _id,
            locality: value,
        })
    }

    render() {
        const { locality } = this.state

        return (
            <Select
                className={styles.selectOption}
                defaultValue={locality}
                onChange={this.onChange}
            >
                <Option value={storeConfig.locality.local.type}>
                    {storeConfig.locality.local.title}
                </Option>
                <Option value={storeConfig.locality.global.type}>
                    {storeConfig.locality.global.title}
                </Option>
            </Select>
        )
    }
}

export default PreviewSelect
