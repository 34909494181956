import React, { Component } from 'react'
import { Icon, Upload, Modal } from 'antd'
import styles from './uploader.scss'
import classNames from 'classnames'
import Validator from '../../util/validator'
import { api } from '../../api'

const { confirm } = Modal

class PreviewEditorLayer extends Component {
    state = {
        imageRoute: this.props.imageRoute,
    }

    remove = () => {
        confirm({
            title: 'Are you sure you want to delete this brand?',
            content: 'Currently there are 0 posts online with this brand',
            onOk: () => {
                const { remove, id } = this.props
                remove(id)
            },
            onCancel: () => {},
        })
    }

    handleImageChange = async info => {
        const { file } = info
        if (file.status === 'uploading') {
            this.setState({ loading: true })
            return
        }
        if (file.status === 'done') {
            await Validator.readImage(file.originFileObj, image =>
                this.setState({
                    image,
                    loading: false,
                    path: file.response.path,
                })
            )
            this.saveImage(file.response.path)
        }
    }

    saveImage = async image => {
        const { id, url } = this.props
        await api.put(url, {
            id: id,
            image: image,
        })
        this.setState({
            imageRoute: api.static + image,
        })
    }

    render() {
        const { imageRoute } = this.state
        const { uploaderUrl } = this.props

        return (
            <div
                className={styles.image}
                style={{ backgroundImage: `url(${imageRoute})` }}
            >
                <div className={styles.editLayer}>
                    <Upload
                        name="image"
                        className={styles.uploader}
                        showUploadList={false}
                        action={api.url + uploaderUrl}
                        beforeUpload={Validator.isImageValid}
                        onChange={this.handleImageChange}
                    >
                        <Icon
                            type="cloud-upload"
                            className={classNames(
                                styles.icon,
                                styles.editHover
                            )}
                        />
                    </Upload>
                    <Icon
                        type="delete"
                        className={classNames(styles.icon, styles.deleteHover)}
                        onClick={this.remove}
                    />
                </div>
            </div>
        )
    }
}

export default PreviewEditorLayer
