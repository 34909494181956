import React, { Component } from 'react'
import { Row, Col, Layout } from 'antd'
import LoginForm from '../../form/loginForm'
import { SAVE_TOKEN } from '../../../redux/actions'
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux'

class Login extends Component {

    state = {
        isLoggedIn: false
    }

    onLogin = data => {
        const {onLoggedIn} = this.props
        onLoggedIn(data.user.token)
        this.setState({
            isLoggedIn: true
        })
    }

    render() {
        const {isLoggedIn} = this.state
        if (isLoggedIn) return <Redirect to='/dashboard'/>
        return (
            <Layout style={{ height: '100vh' }}>
                <Row
                    type="flex"
                    justify="center"
                    align="middle"
                    style={{ height: '100%' }}
                >
                    <Col span={4}>
                        <LoginForm
                            onLogin={this.onLogin}
                        />
                    </Col>
                </Row>
            </Layout>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    onLoggedIn: token => dispatch({type: SAVE_TOKEN, token: token})
})

export default connect(null, mapDispatchToProps)(Login)
