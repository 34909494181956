import React, { Component } from 'react'
import MainLayout from '../../layout/mainLayout'
import { Typography } from 'antd'
import BasicUploaderContainer from '../../uploaderContainers/basicUploaderContainer'
import { routes } from '../../../api'

const { Title } = Typography

class Categories extends Component {
    render() {
        return (
            <div>
                <MainLayout>
                    <Title>Categories</Title>
                    <BasicUploaderContainer
                        url={routes.categoryApi}
                        uploaderUrl={routes.categoryUpload}
                    />
                </MainLayout>
            </div>
        )
    }
}

export default Categories
