import React from 'react'
import { Row, Col, Button } from 'antd'
import styles from './styles.scss'

const PublishButton = props => {
    const publish = () => {
        props.publish(Date.now())
    }

    return (
        <Row type="flex" justify="center" align="middle" gutter={20}>
            <Col span={24}>
                <Button
                    type="primary"
                    className={styles.button}
                    onClick={publish}
                >
                    {props.title}
                </Button>
            </Col>
        </Row>
    )
}

export default PublishButton
