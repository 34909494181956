export const storeConfig = {
    locality: {
        local: {
            title: 'German Stores',
            type: 'local',
        },
        global: {
            title: 'Global Stores',
            type: 'global',
        },
    },
}
