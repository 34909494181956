import React, { Component } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import {
    convertToRaw,
    convertFromHTML,
    EditorState,
    ContentState,
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import './styles.scss'

class TextEditor extends Component {
    state = {
        editorState: this.props.value
            ? EditorState.createWithContent(
                  ContentState.createFromBlockArray(
                      convertFromHTML(this.props.value)
                  )
              )
            : EditorState.createEmpty(),
    }

    onChange = editorState => {
        const { onChange } = this.props
        this.setState({ editorState })
        onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    }

    render() {
        return (
            <Editor
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={this.onChange}
                editorState={this.state.editorState}
            />
        )
    }
}

export default TextEditor
