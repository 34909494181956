import * as actionTypes from './actions'

const initialState = {
    token: {}
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SAVE_TOKEN:
            return {
                ...state,
                token: action.token
            }
        default:
            return state
    }
}

export default reducer