export const menuItems = [
    {
        title: 'Dashboard',
        icon: 'home',
        target: '/dashboard',
    },
    {
        title: 'Products',
        icon: 'fire',
        target: '/products',
    },
    {
        title: 'Add Product',
        icon: 'file-add',
        target: '/add-product',
    },
    {
        title: 'Categories',
        icon: 'book',
        target: '/categories',
    },
    {
        title: 'Stores',
        icon: 'appstore',
        target: '/stores',
    },
    {
        title: 'Brands',
        icon: 'crown',
        target: '/brands',
    },
    // {
    //     title: 'Site Settings',
    //     icon: 'setting',
    //     target: '/site-settings',
    // },
    {
        title: 'Imprint',
        icon: 'file-text',
        target: '/imprint',
    }
]
