import React from 'react'
import MainLayout from '../../layout/mainLayout'
import { Typography } from 'antd'
import ProductEditor from '../../editor/editor'
import { withRouter } from 'react-router'

const { Title } = Typography

const Products = props => {
    const {
        match: { params },
    } = props
    const id = params.id

    return (
        <div>
            <MainLayout>
                <Title>Products</Title>
                <ProductEditor id={id} />
            </MainLayout>
        </div>
    )
}

export default withRouter(Products)
