import React, { Component } from 'react'
import { Upload, Icon, Modal } from 'antd'
import { api } from '../../api'
import { routes } from '../../api'

const FileStatus = {
    UPLOADING: 'uploading',
    SUCCESS: 'success',
}

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
    })
}

class ProductImageUploader extends Component {
    state = {
        previewVisible: false,
        previewImage: '',
        fileList: this.props.images,
    }

    handleCancel = () => this.setState({ previewVisible: false })

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        })
    }

    handleChange = props => {
        const { onImageUploaded } = this.props

        this.setState({ fileList: props.fileList })
        onImageUploaded(props.fileList)
    }

    convertFileListFromProps = () => {
        const { fileList } = this.state
        if (fileList.length > 0 && !fileList[0].name) {
            fileList.forEach((part, index, theList) => {
                theList[index].name = part.sourceAlias
                theList[index].key = part._id
                theList[index].uid = part._id
                theList[index].status = 'done'
            })
        }
        return fileList
    }

    render() {
        const { previewVisible, previewImage } = this.state
        const fileList = this.convertFileListFromProps()
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">Upload</div>
            </div>
        )
        return (
            <div className="clearfix">
                <Upload
                    name="image"
                    action={`${api.url}${routes.productImageUpload}`}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                    className="product-bulk-image-uploader"
                >
                    {fileList.length >= 8 ? null : uploadButton}
                </Upload>
                <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <img
                        alt="example"
                        style={{ width: '100%' }}
                        src={previewImage}
                    />
                </Modal>
            </div>
        )
    }
}

export default ProductImageUploader
