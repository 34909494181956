import { api } from '../api'

const Routes = {
    LOGIN: '/login',
    REGISTER: '/register'
}

class UserApi {

    login(details) {
        return api.post(Routes.LOGIN, details)
    }

    register() {
        return api.post(Routes.REGISTER, {user: {
            username: 'vurus',
            password: 'sneaker675'
        }});
    }

}

export default new UserApi()