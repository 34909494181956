import { api } from '../api'

const routes = {
    GET_PRODUCT_BY_ID: '/product/',
    PUBLISH: '/product/publish',
    UPDATE: '/product',
    DELETE: '/product',
}

class ProductApi {
    getProductById = id => {
        return api.get(routes.GET_PRODUCT_BY_ID + id)
    }

    publish = article => {
        return api.post(routes.PUBLISH, article)
    }

    delete = id => {
        return api.delete(routes.DELETE, id)
    }

    update = article => {
        return api.update(routes.UPDATE, article)
    }
}

export default new ProductApi()
