import React, { Component } from 'react'
import { api } from '../../../api'
import StoreSelectorItem from './storeSelectorItem'

class StoreSelector extends Component {
    state = {
        stores: [],
        selectedStores: this.props.selectedStores || [],
        isLoading: true,
    }

    componentDidMount() {
        this.fetchStores()
    }

    fetchStores = async () => {
        const { selectedStores } = this.state
        const stores = await api.get('/store')

        stores.data.forEach((part, index, collection) => {
            selectedStores.map(store => {
                if (collection[index]._id === store._id) {
                    collection[index] = {
                        ...store,
                        isSelected: true,
                        status: store.status.toUpperCase().replace('-', '_'),
                    }
                }
            })
        })

        this.setState({
            stores: stores.data,
            isLoading: false,
        })
    }

    modify = modifiedStore => {
        const { onChange } = this.props
        const selectedStoreCopy = [...this.state.selectedStores]
        let indexInSelectedStores = selectedStoreCopy.findIndex(
            store => store._id === modifiedStore._id
        )

        if (indexInSelectedStores !== -1) {
            selectedStoreCopy[indexInSelectedStores] = modifiedStore
        } else {
            selectedStoreCopy.push(modifiedStore)
        }
        this.setState({
            selectedStores: selectedStoreCopy,
        })
        onChange(selectedStoreCopy)
    }

    remove = storeId => {
        const { onChange } = this.props
        const { selectedStores } = this.state
        const storeToRemove = selectedStores.findIndex(
            store => store._id === storeId
        )
        selectedStores.splice(storeToRemove, 1)
        this.setState({
            selectedStores,
        })
        onChange(selectedStores)
    }

    onStoreItemModified = store => {
        if (store.isSelected) {
            this.modify(store)
        } else {
            this.remove(store._id)
        }
    }

    render() {
        const { stores } = this.state
        const { filter } = this.props

        return (
            <div>
                {stores &&
                    stores.map(store => {
                        if (filter !== store.locality) return
                        return (
                            <StoreSelectorItem
                                store={store}
                                key={store._id}
                                onChange={this.onStoreItemModified}
                                isSelected={store.isSelected}
                            />
                        )
                    })}
            </div>
        )
    }
}

export default StoreSelector
