import React, { Component } from 'react'
import { Card, Input, Col, Icon } from 'antd'
import styles from './styles.scss'
import StatusSelector from '../statusSelector/statusSelector'

class StoreSelectorItem extends Component {
    state = {
        ...this.props.store,
        isSelected: this.props.isSelected,
    }

    remove = async () => {
        await this.setState({
            isSelected: false,
            link: '',
            status: '',
        })
        this.props.onChange(this.state)
    }

    onLinkChange = async ev => {
        await this.setState({
            link: ev.target.value,
            isSelected: true,
        })
        this.props.onChange(this.state)
    }

    onStatusChange = async status => {
        await this.setState({
            status,
            isSelected: true,
        })
        this.props.onChange(this.state)
    }

    render() {
        const { link, isSelected } = this.state
        const { store } = this.props
        const imageLink = store.image

        return (
            <Col
                span={6}
                key={store._id}
                className={styles.card}
                style={isSelected ? { opacity: 1 } : ''}
                onClick={this.onClick}
            >
                <Card
                    hoverable
                    cover={
                        <span
                            className={styles.image}
                            style={{ backgroundImage: `url(${imageLink})` }}
                        >
                            &nbsp;
                        </span>
                    }
                >
                    <Icon
                        type="close-circle"
                        className={styles.remove}
                        onClick={this.remove}
                    />
                    <Input
                        placeholder="Store link"
                        value={link}
                        onChange={this.onLinkChange}
                    />
                    <StatusSelector
                        onChange={this.onStatusChange}
                        hideText={true}
                        status={store.status}
                    />
                </Card>
            </Col>
        )
    }
}

export default StoreSelectorItem
