import React, { Component } from 'react'
import MainLayout from '../../layout/mainLayout'

class Dashboard extends Component {
    render() {
        return (
            <div>
                <MainLayout>
                    <h1>This is the dashboard</h1>
                </MainLayout>
            </div>
        )
    }
}

export default Dashboard
