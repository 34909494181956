import { message, notification } from 'antd'

const showNotification = (message, type) => {
    notification[type]({
        message: message,
        duration: 2,
    })
}

class Validator {
    isBrandValid(brand) {
        if (!brand.name) {
            showNotification('Title is missing', 'error')
            return false
        }
        if (!brand.logo) {
            showNotification('Logo is missing', 'error')
            return false
        }
        return true
    }

    isStoreValid(store) {
        if (!store.image) {
            showNotification('Image is missing', 'error')
            return false
        }
        return true
    }

    isImageValid(file) {
        const acceptedImageTypes = ['image/jpg', 'image/jpeg', 'image/png']
        if (!acceptedImageTypes.includes(file.type.toLowerCase())) {
            message.error('Allowed file types: JPEG, PNG, JPG')
        }
        const isLt2M = file.size / 1024 / 1024 < 10
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!')
        }
    }

    readImage(image, callback) {
        const reader = new FileReader()
        reader.addEventListener('load', () => callback(reader.result))
        reader.readAsDataURL(image)
    }
}

export default new Validator()
