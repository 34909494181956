import React, { Component } from 'react'
import MainLayout from '../../layout/mainLayout'
import { Typography } from 'antd'
import { routes } from '../../../api'
import StoreUploaderContainer from '../../uploaderContainers/storeUploaderContainer'

const { Title } = Typography

class Stores extends Component {
    render() {
        return (
            <div>
                <MainLayout>
                    <Title>Stores</Title>
                    <StoreUploaderContainer
                        url={routes.storeApi}
                        uploaderUrl={routes.storeUpload}
                    />
                </MainLayout>
            </div>
        )
    }
}

export default Stores
