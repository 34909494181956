import { api } from '../api'

const Routes = {
    SAVE: '/imprint/save',
    GET: '/imprint/get'
}

class ImprintApi {
    save (content) {
        return api.post(Routes.SAVE, content)
    }

    get () {
        return api.get(Routes.GET);
    }
}

export default new ImprintApi()