import React, { Component } from 'react'
import { Tag } from 'antd'
const { CheckableTag } = Tag
import { api } from '../../api'
import Preview from '../previews/preview'

class CategorySelector extends Component {
    state = {
        isLoading: true,
        selected: this.props.selected || {},
        categories: [],
    }

    componentDidMount() {
        this.fetchCategories()
    }

    fetchCategories = async () => {
        const categories = await api.get('/category')
        this.setState({
            categories: categories.data,
            isLoading: false,
        })
    }

    handleChange(category) {
        const { onSelect } = this.props
        this.setState({
            selected: category,
        })
        onSelect(category._id)
    }

    render() {
        const { selected, categories } = this.state
        return (
            <div>
                {categories &&
                    categories.map(category => (
                        <CheckableTag
                            key={category._id}
                            checked={selected._id === category._id}
                            onChange={checked =>
                                this.handleChange(category, checked)
                            }
                        >
                            <Preview
                                logo={category.logo}
                                url={category.url}
                                name={category.name}
                                _id={category._id}
                                key={category._id}
                            />
                        </CheckableTag>
                    ))}
            </div>
        )
    }
}

export default CategorySelector
