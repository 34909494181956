import React, { Component } from 'react'
import { Layout, Row } from 'antd'
import Header from '../header/header'
import styles from './mainLayout.scss'
import Sidebar from '../sidebar/sidebar'

class MainLayout extends Component {
    render() {
        return (
            <div>
                <Layout>
                    <Sidebar />
                    <Layout>
                        <Header />
                        <Layout.Content className={styles.content}>
                            <Row>{this.props.children}</Row>
                        </Layout.Content>
                        <Layout.Footer theme="light" />
                    </Layout>
                </Layout>
            </div>
        )
    }
}

export default MainLayout
