import React, { Component } from 'react'
import { Layout, Row } from 'antd'
import ProfileMenu from '../profileMenu/profileMenu'
import styles from './styles.scss'

class Header extends Component {
    render() {
        return (
            <Layout.Header theme="light" className={styles.header}>
                <Row type="flex" justify="end">
                    <ProfileMenu />
                </Row>
            </Layout.Header>
        )
    }
}

export default Header
