import { Upload, Icon, Input, Button, Select } from 'antd'
import React from 'react'
import styles from './basicUploader.scss'
import { api } from '../../api'
import { storeConfig } from '../../config/stores'
import classNames from 'classnames'
import Validator from '../../util/validator'

const { Option } = Select

function getBase64(img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
}

class BasicUploader extends React.Component {
    initialState = {
        loading: false,
        logo: '',
        title: '',
        locality: storeConfig.locality.local.type,
    }

    constructor() {
        super()
        this.state = this.initialState
    }

    handleChange = info => {
        const { file } = info

        if (file.status === 'uploading') {
            this.setState({ loading: true })
            return
        }
        if (file.status === 'done') {
            // Get this url from response in real world.
            getBase64(file.originFileObj, logo => {
                this.setState({
                    logo,
                    loading: false,
                    path: file.response.path,
                })
            })
        }
    }

    add = async () => {
        const { path, title, locality } = this.state
        const { add } = this.props
        const item = await add({
            locality,
            name: title || locality,
            logo: path,
        })
        if (item) this.reset()
    }

    reset = () => {
        this.setState(this.initialState)
    }

    onTitleChange = ev => {
        this.setState({
            title: ev.target.value,
        })
    }

    getInput = () => {
        const { title } = this.state

        return (
            <Input
                placeholder="Add brand title"
                value={title}
                onChange={this.onTitleChange}
                className={styles.input}
            />
        )
    }

    onSelectChange = value => {
        this.setState({
            locality: value,
        })
    }

    getSelect = () => {
        const { locality } = this.state
        return (
            <Select
                className={styles.selectOption}
                defaultValue={locality}
                onChange={this.onSelectChange}
            >
                <Option value={storeConfig.locality.local.type}>
                    {storeConfig.locality.local.title}
                </Option>
                <Option value={storeConfig.locality.global.type}>
                    {storeConfig.locality.global.title}
                </Option>
            </Select>
        )
    }

    render() {
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        )
        const { logo } = this.state
        const { isSelectOption } = this.props

        return (
            <div className={classNames(styles.container, 'basic-uploader')}>
                {!logo && (
                    <Upload
                        name="image"
                        listType="picture-card"
                        className={styles.uploader}
                        showUploadList={false}
                        action={`${api.url}${this.props.url}`}
                        beforeUpload={Validator.isImageValid}
                        onChange={this.handleChange}
                    >
                        {uploadButton}
                    </Upload>
                )}
                {logo && (
                    <img src={logo} className={styles.image} alt="avatar" />
                )}
                {isSelectOption ? this.getSelect() : this.getInput()}
                <Button onClick={this.add}>Add Brand</Button>
            </div>
        )
    }
}

export default BasicUploader
