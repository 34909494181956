import React from 'react'
import { Upload, Icon, Input, Row, Col } from 'antd'
import './styles.scss'
import Validator from '../../util/validator'
import { api, routes } from '../../api'

class CoverUploader extends React.Component {
    state = {
        loading: false,
        image: this.props.image && this.props.image.url,
        seoText: this.props.image && this.props.image.seoText,
        sourceUrl: this.props.image && this.props.image.sourceUrl,
        sourceAlias: this.props.image && this.props.image.sourceAlias,
    }

    handleChange = async info => {
        const { file } = info
        if (file.status === 'uploading') {
            this.setState({ loading: true })
            return
        }
        if (file.status === 'done') {
            const { onChange } = this.props
            // Get this url from response in real world.
            await Validator.readImage(file.originFileObj, async image => {
                await this.setState({
                    image: image,
                    loading: false,
                    sourceUrl: process.env.STATIC + '/images/' + file.name,
                    url: process.env.STATIC + '/images/' + file.name,
                    thumbnail: process.env.STATIC + '/images/' + file.name,
                })
                onChange(this.state)
            })
        }
    }

    onChange = async ev => {
        const { onChange } = this.props
        await this.setState({
            seoText: ev.target.value
        })
        onChange(this.state)
    }

    onUrlChange = async ev => {
        const {onUrlChange} = this.props
        await this.setState({
            sourceUrl: ev.target.value
        })
        onUrlChange(this.state)
    }

    render() {
        const { title } = this.props
        const { image } = this.state

        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">{title}</div>
            </div>
        )

        return (
            <div>
                <Upload
                    name="image"
                    listType="picture-card"
                    className="CoverUploader-uploader"
                    showUploadList={false}
                    action={`${api.url}${routes.productImageUpload}`}
                    beforeUpload={Validator.isImageValid}
                    onChange={this.handleChange}
                    style={{ width: '100%' }}
                >
                    {image ? (
                        <img
                            src={image}
                            alt="CoverUploader"
                            style={{ width: '100%' }}
                        />
                    ) : (
                        uploadButton
                    )}
                </Upload>
                <Row>
                    <Col span={12}>
                        <Input
                            placeholder="Cover seo text"
                            onChange={this.onChange}
                            value={this.state.seoText}
                        />
                    </Col>
                    <Col span={12}>
                        <Input
                            placeholder="Cover seo text"
                            onChange={this.onUrlChange}
                            value={this.state.sourceUrl}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default CoverUploader
